<template>
  <div class="main">
    <img src="/img/static/logo-landing.svg" />
    <h1>Data Privacy Addendum</h1>
    <h4>Last Updated: March 22st, 2021</h4>
    <p>
      This Data Privacy Addendum (“Addendum”) is subject to the terms of, and
      fully incorporated and made part of, the securCom Ltd.(“securCom”) Terms
      Of Service, located at
      <a target="_blank" href="https://www.securcom.me/legal/terms-of-service"
        >https://www.securcom.me/legal/terms-of-service-conditions.pdf</a
      >
       (the “Agreement”). It applies in respect of the provision
      of the securCom Services to the Customer if the Processing of User
      Personal Data is subject to the GDPR, only to the extent the Customer is a
      Controller (or Processor, as applicable) of User Personal Data and
      securCom is a Processor or sub-Processor of User Personal Data (as defined
      below). This Addendum shall amend and supplement any provisions relating
      to the processing of User Personal Data contained in the Agreement, and
      shall be effective for the term of the Agreement.
    </p>
    <br />
    <h4>1. DEFINITIONS.</h4>
    <p class="pl-20"> <strong>1.1.</strong> For the purposes of this Addendum:</p>
    <ul>
      <li>
        “User Personal Data” means Personal Data uploaded to or published,
        displayed or backed up through the securCom Services, as further
        described under Section 3 of this Addendum;
      </li>
      <li>
        “GDPR” means the General Data Protection Regulation (EU) 2016/679,
        together with any national implementing laws in any Member State of the
        European Union, as amended, repealed, consolidated or replaced from time
        to time; and
      </li>
      <li>
        “Personal Data”, “Personal Data Breach”, “Data Subject”, “Data
        Protection Authority”, “Data Protection Impact Assessment”, “Process”,
        “Processor” and “Controller” will each have the meaning given to them in
        Article 4 of the GDPR.
      </li>
    </ul>
    <p class="pl-20">
      <strong>1.2.</strong> Capitalized terms not otherwise defined herein shall have the meaning
      given to them in the Agreement.
    </p>
    <br />
    <h4>2. Details Of The Processing.</h4>
    <ol class="noB">
      <li class="pt-10">
        <strong>2.1.</strong>
        <strong class="ml-5">Categories of Data Subjects.</strong> This Addendum
        applies to the Processing of User Personal Data relating to Customer’s
        clients or prospects, suppliers, business partners, vendors and other
        end users, the extent of which is determined and controlled by Customer
        in its sole discretion
      </li>
      <li class="pt-10">
        <strong>2.2.</strong>
        <strong class="ml-5">Types of Personal Data.</strong> User Personal Data
        includes Personal Data, the extent of which is determined and controlled
        by Customer in its sole discretion, contained in any applications,
        files, data, information or other content uploaded to or published,
        displayed or backed up by Customer or its end users through the securCom
        Services
      </li>
    </ol>
    <br />
    <h4>3. Processing Of User Personal Data</h4>
    <ol class="noB">
      <li class="pt-10">
        <strong>3.1.</strong> <strong class="ml-5"></strong> For purposes of
        this Addendum, Customer and securCom agree that Customer is the
        Controller of User Personal Data and securCom is the Processor of such
        data, except when Customer acts as a Processor of User Personal Data, in
        which case securCom is a sub-Processor. If Customer is a Processor,
        Customer warrants that Customer’s instructions to securCom with respect
        to that User Personal Data, including Customer’s designation of securCom
        as a sub-Processor, have been authorized by the relevant Controller.
      </li>
      <li class="pt-10">
        <strong>3.2.</strong> <strong class="ml-5"></strong> securCom will only
        Process User Personal Data on behalf of and in accordance with the
        Customer’s prior written instructions and for no other purpose. securCom
        is hereby instructed to Process User Personal Data to the extent
        necessary to enable securCom to provide the securCom Services in
        accordance with the Agreement.
      </li>
      <li class="pt-10">
        <strong>3.3.</strong> <strong class="ml-5"></strong> Each of the
        Customer and securCom will comply with their respective obligations
        under the GDPR, to the extent applicable to the Processing of any User
        Personal Data in the context of the provision of the securCom Services.
        Customer will (i) comply with all applicable privacy and data protection
        laws with respect to Customer’s Processing of User Personal Data and any
        Processing instructions that Customer issues to securCom, and (ii)
        ensure that Customer has obtained (or will obtain) all consents and
        rights necessary for securCom to Process User Personal Data in
        accordance with this Addendum
      </li>
      <li class="pt-10">
        <strong>3.4.</strong> <strong class="ml-5"></strong> Customer will
        select the country where User Personal Data will be stored. Customer
        consents to the storage of the User Personal Data in the country that
        Customer chooses when Customer purchases specific Services. By uploading
        User Personal Data to the Services, Customer acknowledges that Customer
        may transfer and access User Personal Data from around the world,
        including to and from the country in which User Personal Data is
        maintained.
      </li>
      <li class="pt-10">
        <strong>3.5.</strong> <strong class="ml-5"></strong> For Customers
        located in the European Economic Area or Switzerland, Customer
        acknowledges that securCom may process User Personal Data in countries
        outside of the European Economic Area and Switzerland as necessary to
        provide the securCom Services and in accordance with the terms of this
        Addendum. Where this is the case, securCom will take such measures as
        are necessary to ensure that the transfer is in compliance with
        applicable data protection laws.
      </li>
      <li class="pt-10">
        <strong>3.6.</strong> <strong class="ml-5"></strong> The Customer
        acknowledges that securCom is reliant on the Customer for direction as
        to the extent to which securCom is entitled to use and Process User
        Personal Data on behalf of Customer in performance of the securCom
        Services. Consequently securCom will not be liable under the Agreement
        for any claim brought by a Data Subject arising from any action or
        omission by securCom, to the extent that such action or omission
        resulted directly from the Customer’s instructions or from Customer’s
        failure to comply with its obligations under the applicable data
        protection law.
      </li>
      <li class="pt-10">
        <strong>3.7.</strong> <strong class="ml-5"></strong> If for any reason
        (including a change in applicable law) securCom becomes unable to comply
        with any instructions of the Customer regarding the Processing of User
        Personal Data, securCom will (a) promptly notify the Customer of such
        inability, providing a reasonable level of detail as to the instructions
        with which it cannot comply and the reasons why it cannot comply, to the
        greatest extent permitted by applicable law; and (b) cease all
        Processing of the affected User Personal Data (other than merely storing
        and maintaining the security of the affected User Personal Data) until
        such time as the Customer issues new instructions with which securCom is
        able to comply. If this provision applies, securCom will not be liable
        to Customer under the Agreement in respect of any failure to perform the
        securCom Services due to its inability to process User Personal Data
        until such time as the Customer issues new instructions in regard to
        such Processing.
      </li>
    </ol>
    <br />
    <h4>4. Confidentiality</h4>
    <p>
      securCom will ensure that any person whom Customer authorizes to Process
      User Personal Data on its behalf is subject to confidentiality obligations
      in respect of that User Personal Data.
    </p>
    <br />
    <h4>5. Security Measures</h4>
    <ol class="noB">
      <li class="pt-10">
        <strong>5.1.</strong> <strong class="ml-5"></strong> securCom will
        implement appropriate technical and organizational measures to protect
        against accidental or unlawful destruction, loss, alteration, authorized
        disclosure of, or access to User Personal Data.
      </li>
      <li class="pt-10">
        <strong>5.2.</strong> <strong class="ml-5"></strong> securCom will, at
        the Customer’s request and subject to the Customer paying all of
        securCom’s fees at prevailing rates, and all expenses, provide the
        Customer with assistance necessary for the fulfillment of the Customer’s
        obligation to keep User Personal Data secure.
      </li>
    </ol>
    <br />
    <h4>6. Sub-processors</h4>
    <p>
      Customer authorizes securCom to engage sub-Processors to perform specific
      services on securCom’s behalf which may require such sub-Processors to
      Process User Personal Data. If securCom engages a sub-Processor to Process
      any User Personal Data, it will:
    </p>
    <ul>
      <li>
        inform Customer of any intended changes concerning the addition or
        replacement of such subProcessors, to the greatest extent permitted by
        applicable law, and Customer will have an opportunity to object to such
        changes on reasonable grounds within fifteen (15) business days after
        being notified. If the parties are unable to resolve such objection,
        either party may terminate the Agreement by providing written notice to
        the other party;
      </li>
      <li>
        keep the Customer informed if there is any change to the role or status
        of the sub-Processor; and
      </li>
      <li>
        enter into a written agreement with the sub-Processor that imposes on
        the sub-Processor the same obligations that apply to securCom under the
        Addendum.
      </li>
    </ul>
    <br />
    <h4>7. Data Subject Rights</h4>
    <p>
      securCom will, at the Customer’s request and subject to the Customer
      paying all of securCom’s fees at prevailing rates, and all expenses,
      provide the Customer with assistance necessary for the fulfillment of the
      Customer’s obligation to respond to requests for the exercise of Data
      Subjects’ rights. Customer shall be solely responsible for responding to
      such requests.
    </p>
    <br />
    <h4>8. Security Breaches</h4>
    <p>securCom will:</p>
    <ul>
      <li>
        notify the Customer as soon as practicable after it becomes aware of any
        Personal Data Breach affecting any User Personal Data; and
      </li>
      <li>
        at the Customer’s request and subject to the Customer paying all of
        securCom’s fees at prevailing rates, and all expenses, promptly provide
        the Customer with all reasonable assistance necessary to enable the
        Customer to notify relevant security breaches to the relevant Data
        Protection Authorities and/or affected Data Subjects.
      </li>
    </ul>
    <br />
    <h4>9. Data Protection Impact Assessment; Prior Consultation</h4>
    <p>
      securCom will, at the Customer’s request and subject to the Customer
      paying all of securCom’s fees at prevailing rates, and all expenses,
      provide the Customer with reasonable assistance to facilitate:
    </p>
    <ul>
      <li>
        the conduct of Data Protection Impact Assessments if the Customer is
        required to do so under the GDPR; and
      </li>
      <li>
        consultation with Data Protection Authorities, if the Customer is
        required to engage in consultation under the GDPR, in each case solely
        to the extent that such assistance is necessary and relates to the
        Processing by the securCom of the User Personal Data, taking into
        account the nature of the Processing and the information available to
        securCom.
      </li>
    </ul>
    <br />
    <h4>10. Deletion of User Personal Data</h4>
    <p>
      On expiration of the Agreement, Customer instructs securCom to permanently
      and securely delete all User Personal Data in the possession or control of
      securCom or any of its sub-Processors, within a reasonable period of time
      (unless the applicable law of the EU or of an EU Member State requires
      otherwise), except if the Customer requests, prior to expiration of the
      Agreement, to have access to the securCom Services in order to retrieve
      User Personal Data in accordance with Clause 9(d) of the Agreement.
    </p>
    <br />
    <h4>11. Information</h4>
    <p>
      securCom will, at Customer’s request and subject to the Customer paying
      all of securCom’s fees at prevailing rates, and all expenses, provide the
      Customer with all information necessary to enable the Customer to
      demonstrate compliance with its obligations under the GDPR, and allow for
      and contribute to audits, including inspections, conducted by the Customer
      or an auditor mandated by the Customer, to the extent that such
      information is within securCom’s control and securCom is not precluded
      from disclosing it by applicable law, a duty of confidentiality, or any
      other obligation owed to a third party.
    </p>
    <br />
    <h4>12. Order of Precedence</h4>
    <p>
      With regard to the subject matter of this Addendum, in the event of
      inconsistencies between the provisions of this Addendum and any other
      agreements between the parties, including the Agreement, the provisions of
      this Addendum shall prevail.
    </p>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {};
</script>

<style scoped>
a {
  color: #409875;
}

p {
  line-height: 1.4rem;
}

li {
  margin-top: 5px;
}

.main {
  height: 91%;
  margin-top: 20px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.noB {
  list-style: none;
  margin-left: -20px;
}

img {
  width: 100%;
  max-width: 230px;
  margin-left: -5px;
}
</style>